import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [{
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'registro',
        loadChildren: () => import('./auth/auth.module').then( m => m.AuthModule )
      },
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then( m => m.HomeModule )
      },
      {
        path: 'about',
        loadChildren: () => import('./about/about.module').then( m => m.AboutModule )
      },
      {
        path: 'company-services',
        loadChildren: () => import('./company-services/company-services.module').then( m => m.CompanyServicesModule )
      },
      // {
      //   path: 'store',
      //   loadChildren: () => import('./store/store.module').then( m => m.StoreModule )
      // }
      {
        path: '**',
        redirectTo: 'home'
      }
    ]
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
