import { HomeService } from './../../../home/services/home.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    private homeService: HomeService,
  ) { }

  ngOnInit(): void {
    this.homeService.getRedes().subscribe( () => {}, err => console.log(err));
  }

  get insta(){
    return this.homeService.insta;
  }

  get fb(){
    return this.homeService.fb;
  }

}
