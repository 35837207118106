import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../../home/services/home.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  menuActivo: boolean = false;
  constructor( private homeService: HomeService ) { }

  ngOnInit() {
    this.homeService.getRedes();
  }

  activarMenu(){
    this.menuActivo = !this.menuActivo;
  }


  get insta(){
    return this.homeService.insta;
  }

  get fb(){
    return this.homeService.fb;
  }



}
