import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// import { environment } from 'src/environments/environment.prod';
import { environment } from 'src/environments/environment';
import { Nosotros } from '../interfaces/nosotros.interface';
import { Portada } from '../interfaces/portada.interface';
import { FrmContacto } from '../interfaces/frm-contacto.interface';
import { Servicios } from '../interfaces/servicios.interface';
import { RedesSociales } from '../interfaces/sociales.interface';
import { FormEmpleoData } from '../interfaces/empleo-form.interface';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  private url_base = environment.url_base;
  private _insta  !: RedesSociales;
  private _fb     !: RedesSociales;

  //
  // ─── RUTAS ──────────────────────────────────────────────────────────────────────
  //

    private portadasUrl   = 'Home/getPortada';
    private nosotrosUrl   = 'Home/getSobreNosotros';
    private contactoUrl   = 'Home/postContactanos';
    private contactoUrl2   = 'Clientes/publicar-solicitud-servicio';
    private serviciosUrl  = 'Home/getServicios';
    private redesUrl      = 'Home/getRedes';
    private postEmpleo   = 'Clientes/publicar-solicitud-empleo';

    private serviciosUrl2 = 'Servicios/servicios-home';
  //
  // ───  ───────────────────────────────────────────────────────────────────────────
  //

 
  constructor( private http: HttpClient ) { }



  getPortadas(): Observable<Portada[] | any>{
    const url = `${this.url_base}/${this.portadasUrl}`;

    return this.http.get<Portada[]>(url)
      .pipe(catchError( error => error ));

  }

  getNosotros(): Observable<Nosotros[] | any>{

    const url = `${this.url_base}/${this.nosotrosUrl}`;

    return this.http.get<Nosotros[]>(url)
      .pipe(catchError(error => error))
  }

  postContacto( datos: FrmContacto ): Observable<boolean>{
    const url = `${this.url_base}/${this.contactoUrl}`;

    return this.http.post<FrmContacto>( url, datos )
      .pipe( 
        map( res => true ),
        catchError( error => of(false) )
      )

  }

  postContacto2( datos: FrmContacto ){
    const url = `${this.url_base}/${this.contactoUrl2}`
    return this.http.post( url, datos );
  }


  //
  // ─── SERVICIOS ──────────────────────────────────────────────────────────────────
  //

    getServicios( imagen: boolean = false ) {
      const url = `${this.url_base}/${this.serviciosUrl2}?imagen=${imagen}`;
      return this.http.get<Servicios[]>( url );
    }


  //
  // ─── REDES SOCIALES ──────────────────────────────────────────────
  //

    getRedes(){
      const url = `${this.url_base}/${this.redesUrl}`;
      return this.http.get<RedesSociales[]>( url )
          .pipe( map( data => {
            this.setInsta( data );
            this.setFb( data );
            console.log(data);
            return true
          }))
    }

    setInsta( data:RedesSociales[] | any ){
      this._insta = data.find( (data:RedesSociales) => data.redSocial === "INSTA" );
    }
    setFb( data:RedesSociales[] | any ){
      this._fb = data.find( (data:RedesSociales) => data.redSocial === "FB" );
    }
    get insta(){
      return this._insta;
    }
    get fb(){
      return this._fb;
    }



    //
    // ──────────────────────────────────────────────────────────────────────────────────────────────────────────────────── I ──────────
    //   :::::: P O S T   F O R M U L A R I O   D E   S O L I C I T U D   D E   E M P L E O : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────────
    //

      sentFormEmpleo( data: FormEmpleoData ){
        const url = `${this.url_base}/${this.postEmpleo}`;

        return this.http.post( url, data )

      }

}
