<app-spinner *ngIf="loading"></app-spinner>
<app-loading-transparent *ngIf="loadingTransp"></app-loading-transparent>

<app-main-nav>
    <app-content></app-content>

    <app-footer></app-footer>

    <div class="fixed-container">
        <a class="up" (click)="scrollToTop()">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler 
                    icon-tabler-chevrons-up" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" fill="none"
                stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <polyline points="7 11 12 6 17 11" />
                <polyline points="7 17 12 12 17 17" />
            </svg>
        </a>
        <!-- +50494467089 -->
        <a  class="round-icon round-icon--llamada" 
            href="tel:+50494467089" 
            target="_blank">
            <svg class="contacto-data--svg action-hover" fill="#ffffff" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                viewBox="0 0 24 24">
                <path
                    d="M18.48 22.926l-1.193.658c-6.979 3.621-19.082-17.494-12.279-21.484l1.145-.637 3.714 6.467-1.139.632c-2.067 1.245 2.76 9.707 4.879 8.545l1.162-.642 3.711 6.461zm-9.808-22.926l-1.68.975 3.714 6.466 1.681-.975-3.715-6.466zm8.613 14.997l-1.68.975 3.714 6.467 1.681-.975-3.715-6.467z" />
            </svg>
        </a>
        <a class="round-icon round-icon--whatsapp" [href]="whatsApp" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg"  width="32"
                height="32" viewBox="0 0 24 24" stroke-width="2" stroke="#ffffff" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
                <path
                    d="M9 10a0.5 .5 0 0 0 1 0v-1a0.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a0.5 .5 0 0 0 0 -1h-1a0.5 .5 0 0 0 0 1" />
            </svg>
        </a>
    </div>

</app-main-nav>