import { Component, ViewChild, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { HomeService } from '../../../home/services/home.service';
import { ComunicacionService } from '../../../home/services/comunicacion.service';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit {

  @ViewChild('drawer') drawer!: MatSidenav;
  @ViewChild(MatSidenavContainer) sidenavContainer!: MatSidenavContainer 

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private homeService: HomeService,
    private comunicacionService: ComunicacionService
  ) { }

  ngOnInit(): void {
    this.homeService.getRedes().subscribe( () => {}, err => console.log(err));
  }

  get insta(){
    return this.homeService.insta;
  }

  get fb(){
    return this.homeService.fb;
  }
  toogleSide(){
    this.drawer.toggle();
  }

  navigate( ancla:string ){
    this.toogleSide();
    
    this.router.navigate(['/home'])
    .then( () => {

      const ele = document.getElementById(ancla);
      
      setTimeout( () => {
        ele?.scrollIntoView()
      },800); 
    });
  }

}
