<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [ngClass]="{'hidden': !(isHandset$ | async)}"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <mat-toolbar>
      <mat-icon (click)="toogleSide()" aria-label="close icon">close</mat-icon>
    </mat-toolbar>

    <div class="navegacion">
      <a class="menu--list-link" (click)="navigate('nosotros')" >Sobre Nosotros</a>
      <a class="menu--list-link" (click)="navigate('servicios')" >Servicios</a>
      <a class="menu--list-link" (click)="navigate('productos')" >Protocolo Servicios</a>
      <a class="menu--list-link" (click)="navigate('personal')">Personal</a>
      <a class="menu--list-link" (click)="navigate('contacto')">Contáctanos</a>
    </div>

  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <a routerLink="/home" class="logo">
        <img class="logo-img"src="../../../../assets/img/logo_KeepClean.png" alt="logo keep clean">
      </a>

      <div class="contenedor-navegacion"
            [ngClass]="{'mostrar': !(isHandset$ | async)}">
            <a class="menu--link" (click)="navigate('nosotros')" >Sobre Nosotros</a>
            <a class="menu--link" (click)="navigate('servicios')" >Servicios</a>
            <a class="menu--link" (click)="navigate('productos')" >Protocolo Servicios</a>
            <a class="menu--link" (click)="navigate('personal')">Personal</a>
            <a class="menu--link" (click)="navigate('contacto')">Contáctanos</a>
      </div>

      <div class="social-media">
        <a [href]="fb?.url" target="_blank" class="social-media--link">
            <svg
                class="social-media--link--logo"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24">
                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 10h-2v2h2v6h3v-6h1.82l.18-2h-2v-.833c0-.478.096-.667.558-.667h1.442v-2.5h-2.404c-1.798 0-2.596.792-2.596 2.308v1.692z"/>
            </svg>
        </a>
       <!--  <a [href] = "insta?.url"  target="_blank" class="social-media--link">
            <svg
                class="social-media--link--logo"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24">
                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 7.082c1.602 0 1.792.006 2.425.035 1.627.074 2.385.845 2.46 2.459.028.633.034.822.034 2.424s-.006 1.792-.034 2.424c-.075 1.613-.832 2.386-2.46 2.46-.633.028-.822.035-2.425.035-1.602 0-1.792-.006-2.424-.035-1.63-.075-2.385-.849-2.46-2.46-.028-.632-.035-.822-.035-2.424s.007-1.792.035-2.424c.074-1.615.832-2.386 2.46-2.46.632-.029.822-.034 2.424-.034zm0-1.082c-1.63 0-1.833.007-2.474.037-2.18.1-3.39 1.309-3.49 3.489-.029.641-.036.845-.036 2.474 0 1.63.007 1.834.036 2.474.1 2.179 1.31 3.39 3.49 3.49.641.029.844.036 2.474.036 1.63 0 1.834-.007 2.475-.036 2.176-.1 3.391-1.309 3.489-3.49.029-.64.036-.844.036-2.474 0-1.629-.007-1.833-.036-2.474-.098-2.177-1.309-3.39-3.489-3.489-.641-.03-.845-.037-2.475-.037zm0 2.919c-1.701 0-3.081 1.379-3.081 3.081s1.38 3.081 3.081 3.081 3.081-1.379 3.081-3.081c0-1.701-1.38-3.081-3.081-3.081zm0 5.081c-1.105 0-2-.895-2-2 0-1.104.895-2 2-2 1.104 0 2.001.895 2.001 2s-.897 2-2.001 2zm3.202-5.922c-.397 0-.72.322-.72.72 0 .397.322.72.72.72.398 0 .721-.322.721-.72 0-.398-.322-.72-.721-.72z"/>
            </svg>
        </a> -->
    </div>

    </mat-toolbar>
    <ng-content></ng-content>
    <!-- Add Content Here -->
  </mat-sidenav-content>
</mat-sidenav-container>
