<footer class="site-footer">
    <div class="contenedor">
        <div class="site-footer--content">
            <div class="contact-info">
                <p class="contact-info--text">(+504) 9446-7089 </p>
                <p class="contact-info--text">Barrio Río de Piedras, 4 calle B, 24 avenida, Sur Oeste, San Pedro Sula, Honduras C.A</p>
                <p class="contact-info--text">mercadeo@keepcleanhn.com</p>
            </div>

            <div class="footer-mapa">
              <!--Tutorial de como hacer el mapa:  https://www.youtube.com/watch?v=QrqMDoi_lBs-->
              <iframe src="https://www.google.com/maps/d/embed?mid=13mtgxz2BbRSzkwySAIJFeolcbNBm3RA&ehbc=2E312F" width="640" height="480"></iframe>
            </div>

            <div class="certification-info">
                <h3>Estamos Certificados Por</h3>
                <div class="contenedor-cert">
                    <img src="../../../../assets/img/NPMA.png" alt="" class="certification-image">
                </div>
            </div>

            <div class="social-media">
                <a [href]="fb?.url" class="social-media--link" target="_blank">
                    <svg
                        class="social-media--link--logo"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24">
                        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 10h-2v2h2v6h3v-6h1.82l.18-2h-2v-.833c0-.478.096-.667.558-.667h1.442v-2.5h-2.404c-1.798 0-2.596.792-2.596 2.308v1.692z"/>
                    </svg>
                </a>
                <!-- <a [href]="insta?.url" class="social-media--link" target="_blank">
                    <svg
                        class="social-media--link--logo"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24">
                        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 7.082c1.602 0 1.792.006 2.425.035 1.627.074 2.385.845 2.46 2.459.028.633.034.822.034 2.424s-.006 1.792-.034 2.424c-.075 1.613-.832 2.386-2.46 2.46-.633.028-.822.035-2.425.035-1.602 0-1.792-.006-2.424-.035-1.63-.075-2.385-.849-2.46-2.46-.028-.632-.035-.822-.035-2.424s.007-1.792.035-2.424c.074-1.615.832-2.386 2.46-2.46.632-.029.822-.034 2.424-.034zm0-1.082c-1.63 0-1.833.007-2.474.037-2.18.1-3.39 1.309-3.49 3.489-.029.641-.036.845-.036 2.474 0 1.63.007 1.834.036 2.474.1 2.179 1.31 3.39 3.49 3.49.641.029.844.036 2.474.036 1.63 0 1.834-.007 2.475-.036 2.176-.1 3.391-1.309 3.489-3.49.029-.64.036-.844.036-2.474 0-1.629-.007-1.833-.036-2.474-.098-2.177-1.309-3.39-3.489-3.489-.641-.03-.845-.037-2.475-.037zm0 2.919c-1.701 0-3.081 1.379-3.081 3.081s1.38 3.081 3.081 3.081 3.081-1.379 3.081-3.081c0-1.701-1.38-3.081-3.081-3.081zm0 5.081c-1.105 0-2-.895-2-2 0-1.104.895-2 2-2 1.104 0 2.001.895 2.001 2s-.897 2-2.001 2zm3.202-5.922c-.397 0-.72.322-.72.72 0 .397.322.72.72.72.398 0 .721-.322.721-.72 0-.398-.322-.72-.721-.72z"/>
                    </svg>
                </a> -->
            </div>

            <div class="copy">
                <p class="copy-text">2021 copyright Keep Clean</p>
                <a class="copy-text" href="https://cit.hn/" target="_blank">Powered By CIT</a>
            </div>
        </div>
    </div>
</footer>
