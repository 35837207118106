import { Component, OnInit } from '@angular/core';
import { delay } from 'rxjs/operators';
import { ComunicacionService } from '../home/services/comunicacion.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  loading: boolean = true;
  loadingTransp: boolean = false;

  constructor( private comunicacionService: ComunicacionService ) { }

  ngOnInit(): void {
    this.loadingOn();
    this.loadingTransparentOn();
  }

  loadingOn(){
    this.comunicacionService.loading
    .pipe(delay(2000))
    .subscribe( data => {
      this.loading = data;
    })
  }

  loadingTransparentOn(){
    this.comunicacionService.loadingTransp
        .subscribe( resp => {
          this.loadingTransp = resp;
        });
  }

  windowsScroll(event: any){
      console.log(event);
  }

  get whatsApp(){
    return `https://api.whatsapp.com/send?phone=50494467089&text=Hola%2C deseo adquirir uno de sus servicios`; 
  }

  goTop(){
    window.scrollTo(0,0);
  }

  scrollToTop(): void {
    
    document.getElementsByTagName('mat-sidenav-content')[0].scrollTo(0, 0);
  }

  
}
